import React from "react"
import Scroller from './scroller'

export default class Hero extends React.Component {
  constructor(props) {
    super(props);
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this);
  }

  render() {
    let linkUrl = `https://donate.h4na.org/give/237270/#!/donation/checkout`;
    return (
      <>
        <header className="masthead">
          <div className="container h-100">
            <div className="row h-50 align-items-center justify-content-center text-center text-sm-right">
              <div className="col align-self-center align-self-sm-start">
                <a className="donate-circle" href={linkUrl + '?amount=30'}>$30</a>
                <a className="donate-circle" href={linkUrl + '?amount=60'}>$60</a>
                <a className="donate-circle" href={linkUrl + '?amount=100'}>$100</a>
                <a className="donate-circle small-text d-none d-sm-inline-block" href={linkUrl + '?amount=0'}>OTHER</a>
                <a className="donate-circle no-circle" href={linkUrl + '?amount=0'}>DONATE</a>
                <div className="tagline pt-2 pt-sm-3">Give Forgotten People</div>
                <div className="tagline">a Chance at Jesus</div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-lg-8 align-self-baseline">
                <a className="btn btn-primary btn-xl js-scroll-trigger" href="#get-involved" onClick={Scroller.handleAnchorScroll}>Learn More</a>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
