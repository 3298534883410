import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Scroller from "../components/scroller"
import Featured from "../components/featured"

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
  }

  render() {
    return (
      <Layout useTransparentNav={true} hideBreadcrumb={true}>
        <Seo title="Home"/>
        <Hero/>
        <section className="pt-4 pb-5 bg-primary" id="get-involved">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col">
                <h2 className="text-white text-uppercase text-center">Get Involved</h2>
                <hr className="divider light mt-3 mb-4"/>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                <Featured title="What is Hope for Native America?" 
                        description={`God is raising up a generation of Native American young people with lives defined by hope!`}
                        url="/about"
                        thumbnail="/video-thumbnails/about.jpg" />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                <Featured title="Equip a Leader" 
                      description={`Equip young Native Christian leaders to reach their communities for Jesus and to be a living example of hope to broken young people.`}
                      url="/get-involved/equip-a-leader"
                      thumbnail="/video-thumbnails/equip-a-leader.jpg" />
              </div>
            </div>
          </div>
        </section>

        <section className="pt-4 pb-5" id="stories">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col">
                <h2 className="text-uppercase text-center">Stories of Hope</h2>
                <hr className="divider dark mt-3 mb-4"/>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                <Featured title="Bringing Love to My People" 
                      description={`"Anger was taking over my life. Hope was hard to come by, and I needed to find answers."`}
                      url="/stories/bringing-love-to-my-people"
                      thumbnail="/video-thumbnails/bringing-love-to-my-people.jpg"
                      author="Weston"
                      location="Arizona" />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                <Featured title="My Passion to Introduce Hope" 
                      description={`"I felt the same despair that so many of my people do. There's hope. You have more potential than you know."`}
                      url="/stories/my-passion-to-introduce-hope"
                      thumbnail="/video-thumbnails/my-passion-to-introduce-hope.jpg"
                      author="Anna"
                      location="Arizona" />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-xl-4">
                <Featured title="From Ashes to a Crown of Beauty" 
                      description={`"I believed I was the only one that experienced sexual abuse. But I learned how to find hope and healing."`}
                      url="/stories/from-ashes-to-a-crown-of-beauty"
                      thumbnail="/video-thumbnails/from-ashes-to-a-crown-of-beauty.jpg"
                      author="Mary"
                      location="Alaska" />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
